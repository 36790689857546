import React from 'react'
import FacebookIcon from '../images/facebook.svg'
import LinkedinIcon from '../images/linkedin.svg'
import InstagramIcon from '../images/instagram.svg'
import TwitterIcon from '../images/twitter.svg'

const SocialLinks = ({animated}) => {
    return (<div className={`social-links ${animated ? "introduction": ""}`}>
        <ul>
            <li><a href="https://www.linkedin.com/in/patrick-cocheteux/" target="_blank" rel="noopener noreferrer"><LinkedinIcon/></a></li>
            <li><a href="https://www.facebook.com/patrick.cocheteux.18" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a></li>
            <li><a href="https://www.instagram.com/pcxavocats/" target="_blank" rel="noopener noreferrer"><InstagramIcon/></a></li>
            <li><a href="https://twitter.com/PatrickCochete1" target="_blank" rel="noopener noreferrer"><TwitterIcon/></a></li>
        </ul>
    </div>)
}

export default SocialLinks