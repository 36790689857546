import React from 'react'

const CalendlyComp = ({animated}) => {
    
    setTimeout(() => {
        let CalendlyINt = setInterval(() => {
            if(typeof window !== 'undefined' && window.Calendly !== null) {
                window.Calendly.initBadgeWidget({ url: 'https://calendly.com/pcxavocats', text: 'Prenez un rendez-vous', branding: true });
        
                setTimeout(() => {document.querySelector('.calendly-badge-content').classList.add('calendly');
                document.querySelector('.calendly-badge-content').innerHTML = 'Prenez un rendez-vous';}, 50);
        
                clearInterval(CalendlyINt);
            }
        }, 1000);
    }, animated ? 5000: 0)

    return (<></>)
}

export default CalendlyComp