/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"


import Header from "./header"
import SocialLinks from './socialLinks'
import CalendlyComp from './calendly'
import "../styles/global.scss"

const Layout = ({ children }) => {
  
  var animeHeader = false;
  if(typeof window !== 'undefined') {
    if(sessionStorage.alreadyVisited == undefined && window.location.pathname == '/pcx/' || sessionStorage.alreadyVisited == undefined && window.location.pathname == '/') {
      animeHeader = true;
      if(animeHeader == true) {
        sessionStorage.alreadyVisited = true;
      }
    }
  }

  if(typeof window !== 'undefined') {
    return (
      <>
        <Header animated={animeHeader}/>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            height: `calc(100vh - 4rem - 60px)`,
            padding: `0rem 1.0875rem 1.45rem`,
            marginTop: '2rem'
          }}
        >
          <main>{children}</main>
          <footer>
            <SocialLinks animated={animeHeader}/>
            <CalendlyComp animated={animeHeader}/>
          </footer>
        </div>
      </>
    )
  }

  return (<></>)
  
}

export default Layout
