import { Link } from "gatsby"
import React from "react"
import Icon from '../images/pcx-avocats.svg'
import FacebookIcon from '../images/facebook.svg'
import LinkedinIcon from '../images/linkedin.svg'
import InstagramIcon from '../images/instagram.svg'
import TwitterIcon from '../images/twitter.svg'

const Header = ({animated}) => {

  return (
    <header
    className={`${animated ? "introduction": ""}`}
    style={{
      background: `#FFFFFF`,
      height: `60px`
    }}
  >
    <div
    
      style={{
        margin: `0 auto`,
        padding: `0.5rem 0.5rem`
      }}
    >
      <nav id="nav-desktop"  className="">
          <div className="icon"><Link to="/"><Icon/></Link></div>
          <div id="menuToggle">
              <input type="checkbox" />
              
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>

              <ul id="menu" className="menu-links">
                <li><Link to="/"><b>I</b>nfo<span></span></Link></li>
                <li><Link to="/offre">O<b>ff</b>re<span></span></Link></li>         
                <li><Link to="/equipe">RESEAU<b>.PCX</b><span></span></Link></li>        
                <li><Link to="/contact">Con<b>tacts</b><span></span></Link></li>   
                <li><Link to="/jobs">J<b>o</b>bs<span></span></Link></li>            
                <li style={{marginTop: `50px`}} className="my-account"><a href="https://v2.paprwork.io/login" rel="noopener noreferrer" target="_blank">Mon <b>com</b>pte<span></span></a></li>                       
                <li className="my-account"><Link to="/payer-une-facture">Pa<b>yer</b> une fa<b>ctu</b>re<span></span></Link></li>         
                <li className="my-account"><a href="https://www.oui-avocats.fr/" rel="noopener noreferrer" target="_blank">Con<b>sultat</b>ion en li<b>gne</b><span></span></a></li>       
              </ul>
              
          </div>          
      </nav>

      <nav id="nav-mobile" role="navigation">                    
          <div id="menuToggle">
              <input type="checkbox" />
              
              <span></span>
              <span></span>
              <span></span>
              
              <ul id="menu">
                <li><Link to="/"><b>I</b>nfo</Link></li>
                <li><Link to="/offre">O<b>ff</b>re</Link></li>                                
                <li><Link to="/equipe">RESEAU<b>.PCX</b></Link></li>        
                <li><Link to="/contact">Con<b>tacts</b></Link></li>   
                <li><Link to="/jobs">J<b>o</b>bs</Link></li>     
                <li className="my-account"><a href="https://v2.paprwork.io/login" rel="noopener noreferrer" target="_blank">Mon <b>com</b>pte</a></li>       
                <li className="my-account"><Link to="/payer-une-facture">Pa<b>yer</b> une fa<b>ctu</b>re</Link></li>         
                <li className="my-account"><a href="https://www.oui-avocats.fr/" rel="noopener noreferrer" target="_blank">Con<b>sultat</b>ion en li<b>gne</b></a></li>       
                <li>
                  <ul className="social-mobile">
                    <li><a href="https://www.linkedin.com/in/patrick-cocheteux/" target="_blank" rel="noopener noreferrer"><LinkedinIcon/></a></li>
                    <li><a href="https://www.facebook.com/patrick.cocheteux.18" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a></li>
                    <li><a href="https://www.instagram.com/pcxavocats/" target="_blank" rel="noopener noreferrer"><InstagramIcon/></a></li>
                    <li><a href="https://twitter.com/PatrickCochete1" target="_blank" rel="noopener noreferrer"><TwitterIcon/></a></li>
                  </ul>
                </li>
              </ul>
              
          </div>          
          <div className="icon"><Link to="/"><Icon/></Link></div>
      </nav>
    </div>
  </header>
  )
    }

export default Header
